export const useCallPopup = () => {

  const {setGlobal, getGlobal} = useGlobals();

  const onOpenCallPopup = (callback) => {
    const callbacks = getGlobal('callPopupCallbacks', []);
    callbacks.push(callback);
    setGlobal('callPopupCallbacks', callbacks);
  }

  const executeCallbacks = () => {
    const callbacks = getGlobal('callPopupCallbacks', []);
    callbacks.forEach(callback => callback());
  }

  const openCallPopup = () => {
    executeCallbacks();
  }

  return {
    onOpenCallPopup,
    openCallPopup
  }
}
